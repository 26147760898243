<template>
    <div class="service-card">

        <div class="service-img">
            <img :src="needObj.defImg" alt="">
        </div>
            
        <h3 class="service-title">{{needObj.title}}</h3>

        <div class="service-text">
            {{needObj.text }}
        </div>


    </div>
</template>

<script>

export default {
    props:{
        needObj:{
            type:Object,
            default:function(){
                return {}
            }
        }
    },
    data() {
        return {

        };
    },
    mounted() {},
    methods: {


    },
};
</script>
<style lang='scss' scoped>
    @import '@css/var.scss';
    $height: 333px;
    .service-card{
        height: $height;
        background: white;
        padding: 24px 18px 0 18px;
        box-shadow: 0px 0px 10px 3px #b1d1f0;
        .service-img {
            img{
                height: 50px;
            }
        }
            
        h3{
            font-size: 21px;
            padding: 32px 0;
        }
        .service-text{
            font-size: 17px;
            line-height: 1.4em;
        }
    }
</style>

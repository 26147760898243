<template>
    <div>
        <section-banner :data="bannerTotalData" :imgList="bannerImgs"></section-banner>

        <!-- 解决方案 -->
        <solution-new></solution-new>

        <!-- 客户权益保障 -->
        <!-- <customer-protection></customer-protection> -->

        <!-- 服务优势 -->
        <service-advantage></service-advantage>

        <!-- 公司动态 -->
        <!-- <company-dynamics :data="companyList"></company-dynamics> -->

        <!-- 行业新闻  -->
        <industry-news :data="industryList"></industry-news>

    </div>
</template>

<script>
import appNode from '@js/app-node';

import SectionBanner from './index/component/banner';
import solution from './index/component/solution';
import CustomerProtection from './index/component/customer-protection';
import CompanyDynamics from './index/component/company-dynamics';
import IndustryNews from './index/component/industry-news';

import solutionNew from './index/component/solution-new.vue';
import serviceAdvantage from './index/component/service-advantage.vue';

export default {
    components: {
        SectionBanner,
        solution,
        CustomerProtection,
        CompanyDynamics,
        IndustryNews,
        solutionNew,
        serviceAdvantage,
    },

    async asyncData(context) {
        let [newsData, bannerTotalData, bannerImgs] = await Promise.all([
            //新闻数据
            appNode.ssrReq
                .call(context, `${context.store.state.api.kjsUrl}/web/article/articlelist.json`, {
                    title: '',
                    pageindex: 1,
                    pagesize: 4,
                    shbj: '9',
                    fbbj: '1',
                    sortorder: 'desc',
                    sortname: 'fbdate',
                })
                .then((data) => data),
            //banner图片
            appNode.ssrReq.call(context, `${context.store.state.api.kjsUrl}/web/home/getbannerdata.json`).then((data) => data),
            //banner数据
            appNode.ssrReq.call(context, `${context.store.state.api.kjsUrl}/web/advertisement/carouselcharts.json`).then((data) => {
                let res = [];

                if (!data) {
                    return [];
                }

                res = data['0100'] || res;

                return res;
            }),
        ]);

        return {
            newsData,
            bannerTotalData,
            bannerImgs,
        };
    },
    head() {
        return {
            data: {},
            meta: [
                {
                    name: 'description',
                    content: this.$store.state.config.baseInfo.description,
                },
                {
                    name: 'keywords',
                    content: this.$store.state.config.baseInfo.keywords,
                },
            ],
        };
    },
    data: () => ({
        companyList: [],
        industryList: [],
    }),
    methods: {},
    mounted() {
        // 公司动态
        // this.companyList = this.newsData.companyList;
        // 行业新闻
        this.industryList = this.newsData.rows;
    },
};
</script>

<style lang="scss" scoped>
section {
    input {
        padding: 0 1em;
        border-radius: 4px;
    }
}
</style>

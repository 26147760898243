<template>
    <div class="container">
        <div class="content">
            <div class="c_title">
                <div class="top_text">客户</div>
                <div class="bottom_text">权益保障</div>
            </div>
            <div class="list">
                <div
                    class="item"
                    v-for="(item,index) in data"
                    :key="index"
                    :style="{textAlign:item.flag ? 'right':'left'}"
                >
                    <img :src="item.img" />
                    <div class="title">{{ item.title }}</div>
                    <div class="text">{{ item.text }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: [
                {
                    img: require('@/static/images/home/icon02.png'),
                    title: '专业的业务研发团队',
                    text: '中惠科技研发团队由15年以上互联网研发经验的精英带队，专注于招标采购业务与尖端技术的有机融合与深度应用',
                    flag: true,
                },
                {
                    img: require('@/static/images/home/icon03.png'),
                    title: '成熟的行业解决方案',
                    text: '坐拥20年一线行业经验，中惠科技在各行各业里均打磨出一套成熟高效的数智化产品与服务，为企业带来一站式的建设及运营方案',
                },
                {
                    img: require('@/static/images/home/icon04.png'),
                    title: '健全的市场商业模式',
                    text: '中惠科技以价值实现与产品交付为原点进行思考设计，已经形成覆盖各方相关主体的健全的商业模式',
                    flag: true,
                },
                {
                    img: require('@/static/images/home/icon01.png'),
                    title: '完善的服务支持体系',
                    text: '为各场景建立预案，7X24小时紧急服务；按需调整，快速响应；开标现场人员指导；设备定期巡检，排查隐患',
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>
<style lang='scss' scoped>
.container {
    background-color: #f8fafc;
    padding-top: 54px;
    padding-bottom: 93px;
    // padding: 54px 20px 93px;
    .content {
        height: 490px;
        text-align: right;
        position: relative;
        background: url('@/static/images/home/circle.png');
        background-repeat: no-repeat;
        background-position: center;
        .c_title {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            margin-top: -10px;

            .top_text {
                font-size: 24px;
                color: #333333;
            }
            .bottom_text {
                font-size: 24px;
                color: #4555f7;
            }
        }
        .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0px 20px;
            .item {
                width: 410px;
                height: 180px;
                background-color: #fefeff;
                border-radius: 0px;
                border: solid 1px #d4ddf0;
                padding: 15px 27px;
                box-sizing: border-box;
                color: #333333;
                margin-bottom: 123px;

                .title {
                    font-size: 20px;
                    margin-bottom: 15px;
                }

                .text {
                    font-size: 15px;
                }
                img {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
}
</style>

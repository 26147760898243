<template>
    <div class="container">
        <div class="content">
            <sub-title
                title="解决方案"
                class="title"
            ></sub-title>
            <div
                class="list"
                v-for="(itemA,indexA) in data"
                :key="indexA"
            >

                <div
                    class="item"
                    :class="itemA.type"
                    v-for="(item,index) in itemA.data"
                    :key="index"
                    @mouseenter="showArrowFun(item)"
                    @mouseleave="hiddenArrowFun(item)"
                >
                    <img
                        :src="item.img"
                        class="img"
                    />
                    <div class="text">
                        <label>{{ item.title }}</label>
                        <img
                            :src="arrowImg"
                            v-if="item.showArrow"
                        />
                    </div>
                    <div class="option">
                        <div class="btn">查看详情 <i class="el-icon-plus"></i></div>
                        <div class="btn">在线咨询 <i class="el-icon-plus"></i></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import SubTitle from './sub-title';
export default {
    components: { SubTitle },
    data() {
        return {
            data: [
                {
                    type: 'top',
                    data: [
                        {
                            title: '招标投标数字化解决方案',
                            img: require('@/static/images/home/icon_02.png'),
                        },
                        {
                            title: '企业采购数字化解决方案',
                            img: require('@/static/images/home/caigou.png'),
                        },
                        {
                            title: '工程咨询企业信息化解决方案',
                            img: require('@/static/images/home/icon_01.png'),
                        },
                    ],
                },
                {
                    type: 'bottom',
                    data: [
                        {
                            title: '全过程工程项目管理信息化解决方案',
                            img: require('@/static/images/home/icon_04.png'),
                        },
                        {
                            title: '企业教培考核数字化解决方案',
                            img: require('@/static/images/home/icon_03.png'),
                        },
                    ],
                },
            ],
            arrowImg: require('@/static/images/home/arrow.png'),
        };
    },
    mounted() {},
    methods: {
        showArrowFun(item) {
            this.data.map((r) => {
                r.data.map((p) => {
                    if (p.title === item.title) {
                        p.showArrow = true;
                        this.$forceUpdate();
                    }
                });
            });
        },
        hiddenArrowFun(item) {
            this.data.map((r) => {
                r.data.map((p) => {
                    p.showArrow = false;
                });
            });
        },
    },
};
</script>
<style lang='scss' scoped>
@import '@css/var.scss';
.container {
    background: #fff;
    padding-top: 106px;
    padding-bottom: 107px;

    .content {
        padding: 5px 5px;
        .title {
            margin-bottom: 99px;
        }
        .list {
            display: flex;

            .top {
                flex: 1;
            }

            .bottom {
                flex: 1;
                margin-top: -1px;
            }

            .item {
                border: solid 1px #e4e4e4;
                padding: 20px;
                transition: all 0.3s;
                color: #333333;

                &:not(:last-child) {
                    margin-right: -1px;
                }

                .img {
                    width: 59px;
                    height: 59px;
                }

                .text {
                    display: flex;
                    justify-content: space-between;
                    font-size: 22px;
                    font-weight: 700;
                    margin-top: 28px;
                    margin-bottom: 26px;
                }

                &:hover .text {
                    color: $primaryColor;
                }

                &:hover {
                    cursor: pointer;
                    background-color: #ffffff;
                    box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.22);
                    border: 1px solid #8a1f94;
                    transform: scale(1.01);
                }

                .option {
                    display: flex;
                    justify-content: space-between;
                    font-size: 16px;

                    .btn {
                        cursor: pointer;
                        &:hover {
                            color: $primaryColor;
                        }
                    }
                }
            }
        }
    }
}
</style>

<template>
    <div class="container">
        <div class="content">
            <sub-title
                title="公司动态"
                class="title"
            ></sub-title>
            <div class="top">
                <div class="left">
                    <div
                        class="item"
                        v-for="(item,index) in leftData"
                        :key="index"
                        @click="jumpDetail(item)"
                    >
                        <img
                            :src="item.filesavepath"
                            class="img"
                        />
                        <div class="substance">
                            <div class="sub-title">{{ item.articletitle }}</div>
                            <div
                                class="text"
                                v-html="item.mainbody"
                            ></div>
                            <div class="btn">
                                <div class="arrow-img"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div
                        class="item"
                        v-for="(item,index) in rightData"
                        :key="index"
                        @click="jumpDetail(item)"
                    >
                        <div class="img">
                            <img :src="item.filesavepath" />
                        </div>
                        <div class="right-part">
                            <div class="sub-title">{{ item.articletitle }}</div>
                            <div
                                class="text"
                                v-html="item.mainbody"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div
                    class="btn"
                    @click="viewMore"
                >查看更多<i class="el-icon-arrow-right"></i></div>
            </div>
        </div>
    </div>
</template>

<script>
import SubTitle from './sub-title';
export default {
    components: { SubTitle },
    props: {
        data: {
            type: Array,
        },
    },
    watch: {
        data(data) {
            data.map((r) => {
                r.mainbody = this.getSimpleText(r.mainbody);
            });
            this.leftData = data.slice(0, 2);
            this.rightData = data.slice(2, 6);
        },
    },
    data() {
        return {
            leftData: [],
            rightData: [],
        };
    },
    mounted() {},
    methods: {
        jumpDetail(item) {
            location.href = '/news/detail?guid=' + item.guid;
        },
        viewMore() {
            location.href = '/news';
        },
        getSimpleText(html) {
            //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
            var re1 = new RegExp('<.+?>', 'g');

            //执行替换成空字符
            var msg = html.replace(re1, '');
            return msg;
        },
    },
};
</script>
<style lang='scss' scoped>
@import '@css/var.scss';
@import '@css/main.scss';
.container {
    padding-top: 76px;
    padding-bottom: 26px;
    background-image: url('@/static/images/home/bg_01.png');
    background-size: 100% 100%;

    .content {
        .title {
            margin-bottom: 70px;
        }

        .top {
            display: flex;

            .left {
                display: flex;
                .item {
                    width: 285px;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:not(:last-child) {
                        margin-right: 20px;
                    }

                    .img {
                        height: 185px;
                        width: 285px;
                        border: none;
                    }
                    .substance {
                        background: #fff;
                        padding: 27px 17px 13px 17px;
                        margin-top: -3px;
                        color: #333333;

                        .sub-title {
                            height: 58px;
                            font-size: 22px;
                            margin-bottom: 30px;
                            font-weight: bold;

                            @include textEllipsis(2);
                        }
                        .text {
                            font-size: 18px;
                            line-height: 23px;
                            height: 69px;

                            @include textEllipsis(3);
                        }
                        .btn {
                            margin-top: 26px;
                            cursor: pointer;
                            display: flex;
                            justify-content: flex-end;
                        }
                    }

                    .arrow-img {
                        width: 26px;
                        height: 26px;
                        background: url('@/static/images/home/circle_arrrow_grey.png')
                            no-repeat;
                        background-size: 100% 100%;
                    }
                    &:hover {
                        transform: scale(1.01);
                    }

                    &:hover .arrow-img {
                        background: url('@/static/images/home/circle_arrrow_black.png')
                            no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }

            .right {
                padding-left: 35px;
                display: flex;
                flex-flow: column;
                justify-content: space-between;
                .item {
                    display: flex;
                    padding-bottom: 16px;
                    cursor: pointer;

                    &:not(:first-child) {
                        padding-top: 16px;
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid rgb(133, 133, 133, 0.38);
                    }

                    img {
                        width: 123px;
                        height: 80px;
                    }
                    .right-part {
                        padding-left: 21px;
                        .sub-title {
                            width: 400px;
                            font-size: 18px;
                            color: #373737;
                            margin-bottom: 16px;

                            @include textEllipsis(1);
                        }

                        .text {
                            font-size: 15px;
                            color: #373737;

                            @include textEllipsis(2);
                        }
                    }

                    &:hover .sub-title {
                        font-weight: bold;
                        color: $primaryColor;
                    }
                }
            }
        }

        .bottom {
            display: flex;
            justify-content: center;
            margin-top: 43px;
            .btn {
                width: 94px;
                height: 33px;
                line-height: 30px;
                border: solid 1px #383839;
                font-size: 14px;
                color: #333333;
                text-align: center;
                cursor: pointer;
                &:hover {
                    color: #fff;
                    background: #383839;
                }
            }
        }
    }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../static/images/home/circle.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container[data-v-83cb0df6]{background-color:#f8fafc;padding-top:54px;padding-bottom:93px}.container .content[data-v-83cb0df6]{height:490px;text-align:right;position:relative;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50%}.container .content .c_title[data-v-83cb0df6]{position:absolute;width:100%;height:100%;display:flex;flex-flow:column;justify-content:center;align-items:center;font-weight:700;margin-top:-10px}.container .content .c_title .top_text[data-v-83cb0df6]{font-size:24px;color:#333}.container .content .c_title .bottom_text[data-v-83cb0df6]{font-size:24px;color:#4555f7}.container .content .list[data-v-83cb0df6]{display:flex;flex-wrap:wrap;justify-content:space-between;padding:0 20px}.container .content .list .item[data-v-83cb0df6]{width:410px;height:180px;background-color:#fefeff;border-radius:0;border:1px solid #d4ddf0;padding:15px 27px;box-sizing:border-box;color:#333;margin-bottom:123px}.container .content .list .item .title[data-v-83cb0df6]{font-size:20px;margin-bottom:15px}.container .content .list .item .text[data-v-83cb0df6]{font-size:15px}.container .content .list .item img[data-v-83cb0df6]{width:32px;height:32px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

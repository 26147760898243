<template>
    <div class="container">
        <div class="content">
            <sub-title
                title="行业新闻"
                class="title"
            ></sub-title>
            <div class="news">
                <div class="left">
                    <img :src="mainNews.filesavepath" />
                    <div class="left-bottom">
                        <div class="date">
                            <div class="day">{{ mainNews.day }}</div>
                            <div class="time">{{ mainNews.time }}</div>
                        </div>
                        <a
                            class="sub-title"
                            target="_blank"
                            :href="`/news/${mainNews.guid}`"
                        >{{ mainNews.articletitle }}</a>
                    </div>
                </div>
                <div class="right">
                    <a
                        class="item"
                        v-for="(item,index) in news"
                        :key="index"
                        target="_blank"
                        :href="`/news/${item.guid}`"
                    >
                        <div class="day">{{ item.day }}</div>
                        <div class="time">{{ item.time }}</div>
                        <div class="sub-title">{{ item.articletitle }}</div>
                        <div
                            class="text"
                            v-html="item.mainbody"
                        ></div>
                        <i class="iconfont icon-jiantou_xiangyou_o arrow"></i>
                    </a>
                </div>
            </div>

            <div class="bottom">
                <a
                class="btn"
                target="_blank"
                href="/news"
                >
                    查看更多<i class="el-icon-arrow-right"></i>
                </a>
            </div>
        </div>
    </div>

</template>

<script>
import SubTitle from './sub-title';
export default {
    components: { SubTitle },
    props: {
        data: {
            type: Array,
        },
    },
    watch: {
        data(data) {
            data.map((r) => {
                //将新闻内容清除格式
                r.mainbody = this.getSimpleText(r.mainbody);
                //获取时间
                if (r.fbdate) {
                    let str = new Date(r.fbdate);
                    r.time = str.getFullYear() + '-' + (str.getMonth() + 1);
                    r.day = str.getDate();
                }
            });
            this.mainNews = data.slice(0, 1)[0];
            this.news = data.slice(1, 4);
        },
    },
    data() {
        return {
            mainNews: {},
            news: [],
        };
    },
    mounted() {},
    methods: {
        jumpDetail(item) {
            location.href = '/news/' + item.guid;
        },
        getSimpleText(html) {
            //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
            var re1 = new RegExp('<.+?>', 'g');

            //执行替换成空字符
            var msg = html.replace(re1, '');
            return msg;
        },
    },
};
</script>
<style lang='scss' scoped>
@import '@css/var.scss';
@import '@css/main.scss';
.container {
    background: #fff;
    padding-top: 70px;
    padding-bottom: 83px;

    .content {
        .title {
            margin-bottom: 61px;
        }

        .news {
            display: flex;
            .left {
                width: 416px;
                img {
                    width: 416px;
                    height: 270px;
                    margin-bottom: -4px;
                }
                .left-bottom {
                    height: 90px;
                    display: flex;
                    background-color: #f7f7f7;
                    cursor: pointer;

                    .date {
                        width: 90px;
                        height: 90px;
                        background-color: #124a94;
                        color: #fff;
                        text-align: center;
                        padding-top: 10px;

                        .day {
                            font-size: 30px;
                        }
                        .time {
                            font-size: 15px;
                            line-height: 30px;
                        }
                    }

                    .sub-title {
                        display: block;
                        width: 326px;
                        line-height: 90px;
                        font-size: 18px;
                        color: #333;
                        padding: 0px 7px 0px 11px;
                        overflow: hidden;

                        @include textEllipsis(1);
                    }
                }
            }

            .right {
                display: flex;
                justify-content: space-between;
                height: 360px;
                margin-left: 14px;

                .item {
                    display: block;
                    width: 247px;
                    height: 362px;
                    padding: 15px 21px;
                    border: solid 1px #ececec;
                    position: relative;
                    cursor: pointer;
                    &:not(:last-child) {
                        margin-right: 14px;
                    }
                    .day {
                        font-size: 36px;
                        color: #124a94;
                    }
                    .time {
                        font-size: 14px;
                        color: #909090;
                    }
                    .sub-title {
                        height: 4.2em;
                        font-size: 20px;
                        color: #343434;
                        margin-top: 18px;
                        margin-bottom: 30px;

                        @include textEllipsis(3);
                    }
                    .text {
                        font-size: 16px;
                        color: #2f2f2f;

                        @include textEllipsis(3);
                    }

                    .arrow {
                        position: absolute;
                        bottom: 30px;
                        font-size: 33px;
                        color: #333333;
                    }

                    &:hover {
                        background-color: #0f4a94;
                    }

                    &:hover .day {
                        color: #fff;
                    }
                    &:hover .time {
                        color: #fff;
                    }
                    &:hover .sub-title {
                        color: #fff;
                    }
                    &:hover .text {
                        color: #fff;
                    }
                    &:hover .arrow {
                        color: #fff;
                    }
                }
            }
        }

        .bottom {
            display: flex;
            justify-content: center;
            margin-top: 43px;
            .btn {
                width: 94px;
                height: 33px;
                line-height: 30px;
                border: solid 1px #383839;
                font-size: 14px;
                color: #333333;
                text-align: center;
                cursor: pointer;
                &:hover {
                    color: #fff;
                    background: #383839;
                }
            }
        }
    }
}
</style>

<template>
    <div class="container">
        <div class="content">
            <sub-title
                title="服务优势"
                class="title"
            ></sub-title>

            
            <div class="all-service-card">
                <service-card 
                    v-for="(item,index) in serviceCardList" 
                    :key="index" 
                    :need-obj="item"
                >
                </service-card>
            </div>

        </div>
    </div>
</template>

<script>
import SubTitle from './sub-title';
import serviceCard from './service-card.vue';

export default {
    components: { 
        SubTitle,
        serviceCard,
    },
    data() {
        return {
            serviceCardList:[
                {
                    defImg:require('@/static/images/service/service1.png'),
                    title:'专业的业务研发团队',
                    text:'中惠科技研发团队由15年以上互联网研发经验的精英带队，专注于招标采购业务与尖端技术的有机融合与深度应用',
                },
                {
                    defImg:require('@/static/images/service/service2.png'),
                    title:'成熟的行业解决方案',
                    text:'坐拥20年一线行业经验，中惠科技在各行各业里均打磨出一套成熟高效的数智化产品与服务，为企业带来一站式的建设及运营方案',
                },
                {
                    defImg:require('@/static/images/service/service3.png'),
                    title:'健全的市场商业模式',
                    text:'中惠科技以价值实现与产品交付为原点进行思考设计，已经形成覆盖各方相关主体的健全的商业模式',
                },
                {
                    defImg:require('@/static/images/service/service4.png'),
                    title:'完善的服务支持体系',
                    text:'为各场景建立预案，7X24小时紧急服务；按需调整，快速响应；开标现场人员指导；设备定期巡检，排查隐患',
                },

  
                
            ],
        };
    },
    mounted() {},
    methods: {


    },
};
</script>
<style lang='scss' scoped>
@import '@css/var.scss';
.container {
    background: #f8fafc;
    .content {
        padding-bottom: 70px;
        .title {
            padding-top: 70px;
            padding-bottom: 70px;
        }

        .all-service-card{
            width: calc(100% - 12px);
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            .service-card{
                flex: 1;
                margin-right:20px ;
            }
            .service-card:nth-last-child(1){
                margin-right:0px ;
            }
        }


    }
}
</style>

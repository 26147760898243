<template>
    <section class="banner">
        <el-carousel
            :interval="5000"
            height="536px"
        >
            <el-carousel-item
                v-for="(item, index) in imgList"
                :key="index"
            >
                <div
                    class="img-con"
                    @click="jump(item)"
                    :style="{cursor: item.linkurl ? 'pointer':''}"
                >
                    <img
                        :src="item.filesavepath"
                        :alt="item.adtitle"
                    />
                </div>
            </el-carousel-item>
        </el-carousel>
        <!-- <div class="achievement">
            <div
                class="block"
                v-for="item in data"
                :key="item.name"
                :style="{background:item.detail ? '#8a1f94':'#fff'}"
            >
                <div
                    class="total"
                    :style="{color:!item.detail  ? '#8a1f94':'#fff'}"
                >
                    <label>{{ item.value }}</label>
                    <div class="unit">{{ item.detail ? item.detail: '+' }}</div>
                </div>
                <div
                    class="name"
                    :style="{color:!item.detail ? '#282828':'#fff'}"
                >{{ item.dicname }}</div>
            </div>
        </div> -->
    </section>
</template>

<script>
export default {
    props: {
        data: { type: Array },
        imgList: {
            type: Array,
            // default: () => [
            // {
            //     remark: require('@/static/images/banner/bg2.png'),
            //     title: '中惠科技',
            // },
            // {
            //     remark: require('@/static/images/banner/bg1.png'),
            //     title: '惠采购',
            // },
            // {
            //     remark: require('@/static/images/banner/bg3.png'),
            //     title: '惠招标',
            // },
            // ],
        },
    },
    data() {
        return {
            // data: [
            //     {
            //         total: 20,
            //         name: '企业沉淀',
            //         unit: '+',
            //         diccode: 'qycd',
            //     },
            //     {
            //         total: 100000,
            //         name: '一起成长的客户',
            //         unit: '+',
            //         diccode: 'czkh',
            //     },
            //     {
            //         total: 2000,
            //         name: '完成的项目合作',
            //         unit: '+',
            //         diccode: 'xmhz',
            //     },
            //     {
            //         total: 25,
            //         name: '业务涉及省市',
            //         unit: '+',
            //         diccode: 'ywss',
            //     },
            //     {
            //         total: 218,
            //         name: '为客户节约总金额',
            //         unit: '万元',
            //         diccode: 'jyje',
            //     },
            // ],
        };
    },
    methods: {
        jump(item) {
            if (item.linkurl) {
                this.$get(
                    `${this.$store.state.api.kjsUrl}/web/advertisement/clicklog.json`,
                    { adguid: item.adguid },
                    (data) => {}
                );
                window.open(item.linkurl);
            }
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
$height: 536px; //500-》536
$fullheight: 635px;
.banner {
    height: $height;
    background: #fff;
    .img-con {
        height: $height;
        position: relative;

        img {
            height: $height;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .achievement {
        width: 1200px;
        height: 120px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.22);

        .block {
            flex: 1;
            text-align: center;
            .total {
                font-size: 40px;
                font-weight: bold;
                margin-top: 17px;
                display: flex;
                justify-content: center;
                .unit {
                    font-size: 27px;
                    font-weight: normal;
                }
            }

            .name {
                font-size: 16px;
            }
        }
    }
}
</style>